import { payloadMergingReducer } from "./lib/payloadMergingReducer";
import { sendAnswer } from "../core-data/sendAnswers";
import { sendStartTime, sendEndTime } from "../core-data/sendTime";
// Just sets payload to store
const initialValue = {
  section: 0,
  question: 0,
  initialized: false,
  totalProgress: 0,
  progressQuestions: 0,
};
export default payloadMergingReducer("SET_PROGRESSION", initialValue);

// https://stackoverflow.com/questions/804118/best-timestamp-format-for-csv-excel
// https://stackoverflow.com/questions/6040515/how-do-i-get-month-and-date-of-javascript-in-2-digit-format
const getDate = () => {
  const currentdate = new Date();
  const datetime =
    +currentdate.getFullYear() +
    "-" +
    ("0" + (currentdate.getMonth() + 1)).slice(-2) +
    "-" +
    currentdate.getDate() +
    " " +
    ("0" + currentdate.getHours()).slice(-2) +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();
  return datetime;
};

export const initProgression = (questionnaireId) => (dispatch, getState) => {
  const guid = getState()?.guid;
  if (!guid) return null;
  const startKey = "q-p-" + guid + "-" + questionnaireId + "-start";
  let startTime = window.localStorage.getItem(startKey);
  if (!startTime) {
    startTime = getDate();
    window.localStorage.setItem(startKey, startTime);
    dispatch(sendStartTime(guid, questionnaireId, startTime));
  }

  const key = "q-p-" + guid + "-" + questionnaireId + "-progress";
  const stored = window.localStorage.getItem(key);
  const progression = stored ? JSON.parse(stored) : initialValue;

  const newProgression = { ...progression, ...{ initialized: true } };
  window.localStorage.setItem(key, JSON.stringify(newProgression));
  dispatch({
    type: "SET_PROGRESSION",
    payload: newProgression,
    time: startTime,
  });
};

const getFirebaseSectionKey = (sectionKey, themeOrdering) => {
  const section = themeOrdering[sectionKey].index;
  return section;
};

const storeAnswer = (sectionKey, questionKey, answer, question) => (dispatch, getState) => {
  if (sectionKey === undefined || questionKey === undefined || !answer || !question) return null;

  const guid = getState()?.guid;
  const themeOrdering = getState()?.themeOrdering?.data;
  const firebaseSectionKey = getFirebaseSectionKey(sectionKey, themeOrdering);
  const firebaseKey = firebaseSectionKey + "-" + questionKey;
  const questionnaireId = getState()?.questionnaire?.id;
  if (!guid || !questionnaireId) return null;
  const endKey = "q-p-" + guid + "-" + questionnaireId + "-end";
  let endTime = window.localStorage.getItem(endKey);
  endTime = getDate();
  window.localStorage.setItem(endKey, endTime);
  dispatch(sendEndTime(guid, questionnaireId, endTime));

  if (question.type === "KYSYMYS" || question.type === "DROPDOWN" || question.type === "PALAUTELOMAKE" || question.type === "MONIVALINTA") {
    dispatch(sendAnswer(firebaseKey, answer, guid, questionnaireId));
  }
};

// TODO - refactor using better data structure. Maybe flattten into single array for easier handling, and calculte the keys too
export const setProgression = (direction, isKysymysSlide) => (dispatch, getState) => {
  const guid = getState()?.guid;
  const progression = getState()?.progression;
  const answers = getState()?.answers;
  const questionnaire = getState()?.questionnaire;
  const questionnaireId = questionnaire?.id || "";
  if (!guid || !questionnaire || !progression) return null;
  const sectionKey = progression.section;
  const totalProgress = progression.totalProgress;
  const progressQuestions = progression.progressQuestions;
  const questionKey = progression.question;
  const sections = questionnaire?.questions || [];
  const section = (sections && sections[sectionKey]) || [];
  const isLastSection = sectionKey >= sections.length - 1;
  const isFirstSection = sectionKey === 0;
  const sectionLength = (key) => sections[key]?.questions?.length || 0;
  //const questionsCount = questionnaire?.questions?.length;
  const questions = section.questions;

  const question = questions[questionKey];
  const isLastQuestion = questionKey >= questions.length - 1;

  const isFirstQuestion = questionKey === 0;
  const answerKey = sectionKey + "-" + questionKey;
  const answer = answers[answerKey];
  dispatch(storeAnswer(sectionKey, questionKey, answer, question));
  let newSectionKey = null;
  let newQuestionKey = null;
  let newTotalProgress = null;
  let newProgressQuestions = null;
  let newFinished = false;
  if (direction === "NEXT") {
    if (isLastQuestion) {
      newSectionKey = sectionKey + 1;
      newQuestionKey = 0;
      newTotalProgress = totalProgress + 1;
      newProgressQuestions = isKysymysSlide ? progressQuestions + 1 : progressQuestions;
      if (isLastSection) {
        newFinished = true;
      }
    } else {
      newQuestionKey = questionKey + 1;
      newSectionKey = sectionKey;
      newTotalProgress = totalProgress + 1;

      newProgressQuestions = isKysymysSlide ? progressQuestions + 1 : progressQuestions;
    }
  }
  let typeOfnewQuestionKey = null;
  // TODO, REFACTOR THIS
  if (direction === "PREVIOUS") {
    if (isFirstQuestion) {
      newSectionKey = sectionKey - 1;
      newQuestionKey = sectionLength(newSectionKey) - 1; // index starts from 0, not from 1
      typeOfnewQuestionKey = sections[newSectionKey]?.questions[newQuestionKey]?.type;

      newTotalProgress = totalProgress - 1;
      newProgressQuestions = typeOfnewQuestionKey === "KYSYMYS" ? progressQuestions - 1 : progressQuestions;
    } else {
      newQuestionKey = questionKey - 1;
      newTotalProgress = totalProgress - 1;
      newSectionKey = sectionKey;
      typeOfnewQuestionKey = sections[newSectionKey]?.questions[newQuestionKey]?.type;
      newProgressQuestions = typeOfnewQuestionKey === "KYSYMYS" ? progressQuestions - 1 : progressQuestions;
    }
  }
  const payload = {
    section: newSectionKey,
    question: newQuestionKey,
    isLastSection,
    isFirstSection,
    isLastQuestion,
    isFirstQuestion,
    totalProgress: newTotalProgress,
    finished: newFinished,
    progressQuestions: newProgressQuestions,
  };
  const key = "q-p-" + guid + "-" + questionnaireId + "-progress";

  if (payload) {
    window.localStorage.setItem(key, JSON.stringify(payload));
  }
  dispatch({ type: "SET_PROGRESSION", payload });
};
