const initialState = { status: "EMPTY", questions: [], id: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_QUESTIONS_START":
      // console.log(state.status, "actionnn2n", action, state);

      if (state.status === "EMPTY" || action.id !== state.id) {
        return {
          ...initialState,
          status: "FETCHING",
        };
      } else {
        return {
          ...state,
          error: "STATE TRANSITION NOT DEFINED",
        };
      }

    case "GET_QUESTIONS_SUCCESS":
      // console.log(state.status, "actionnnn", action, state);
      if (state.status === "FETCHING" || action.id !== state.id) {
        const lang = action.lang || "fi";
        if (lang === "all") {
          return {
            id: action.id,
            themeOrder: "", //
            questionsEn: action.payload.questionsEn,
            questions: action.payload.questions,
            status: "SUCCESS",
          };
        }
        let propName = "questionsFi";
        if (lang === "en") propName = "questionsEn" || action.payload.questions;

        const questions = action.payload[propName] || action.payload.questions;

        return {
          id: action.id,
          questions: questions,
          status: "SUCCESS",
        };
      } else {
        return {
          ...state,
          error: "STATE TRANSITION NOT DEFINED",
        };
      }
    default:
      return state;
  }
};

export default reducer;
