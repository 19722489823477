const initialState = { isOpen: null };
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_QUESTIONNAIRE_SUCCESS": {
      return { ...state, ...action.payload };
    }
    case "CLOSE_QUESTIONNAIRE_SUCCESS": {
      return { ...state, ...action.payload };
    }
    case "RENAME_QUESTIONNAIRE_SUCCESS": {
      return { ...state, ...action.payload };
    }
    case "GET_QUESTIONNAIRE_SUCCESS": {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
