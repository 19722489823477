const initialState = {};
const reducer = (state = initialState, action) => {
  const { key, answer } = action;
  if (!key || !answer) return state;

  switch (action.type) {
    case 'ANSWER_EDIT':
      if (key) {
        return {
          ...state,
          [key]: answer
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default reducer;

export const editAnswer = (key, answer) => () => ({
  type: 'ANSWER_EDIT',
  key,
  answer
});
