const initialState = { data: null };

const payloadReducer = (key) => (state = initialState, action) => {
  switch (action.type) {
    case "SET_" + key:
      if (action.payload) {
        return { data: action.payload };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default payloadReducer;
