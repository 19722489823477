const initialState = {};
const reducer = (state = initialState, action) => {
  switch (action?.type) {
    case "GET_RESULTS_RECEIVE":
      if (action.payload && action.id) {
        return { ...state, [action.id]: action.payload };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default reducer;
