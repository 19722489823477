import { db } from "./initFirebase";

const startTimeKey = "START_TIME";
const endTimeKey = "END_TIME";
// sendAnswer(answerKey, answer, question.type, guid, questionnaireId)
//     dispatch(sendStartTime(guid, questionnaireId, startTime));
const sendTime = (guid, questionnaireId, timeDate, timeKey) => {
  return (dispatch) => {
    if (!guid || !timeDate || !questionnaireId) {
      console.error("Missing details for sending start time");
      return "";
    }
    const payload = {
      [timeKey]: timeDate || "",
    };
    dispatch({ type: "SEND_START_TIME" });

    db.collection("answers")
      .doc(questionnaireId)
      .collection("answers")
      .doc(guid)
      .set(payload, { merge: true })
      .then(() => {
        dispatch({ type: "SEND_TIME_SUCCESS" });
      })
      .catch((error) => {
        console.log(error, payload);
        dispatch({ type: "SEND_TIME_ERROR" });
      });
  };
};

export const sendStartTime = (guid, questionnaireId, startTime) => {
  return sendTime(guid, questionnaireId, startTime, startTimeKey);
};

export const sendEndTime = (guid, questionnaireId, endTime) => {
  return sendTime(guid, questionnaireId, endTime, endTimeKey);
};
