// import { generateGuid } from "./lib/generateGuid";
// import { payloadReducer } from "./lib/payloadReducer";

// Just sets payload to store
const initialState = null;
const guidReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SIGNIN_SUCCESS__UID':
      // console.log(action.payload)
      if (action.payload) {
        //const key = 'q-' + action.questionnaireId + '-GUID';
        //window.localStorage.setItem(key, action.payload);
        return action.payload;
      } else {
        return state;
      }
    case 'SIGNOUT_SUCCESS':
      return initialState;
    default:
      return state;
  }
};
export default guidReducer;

// // actions
// // fetches GUID from local storage.
// // If it does not exist - generates a new one and stores it to local storage.
// export const setGuid = (questionnaireId) => (dispatch) => {
//     if (!questionnaireId) return null;
//     const key = 'q-' + questionnaireId + '-GUID';
//     let guid = window.localStorage.getItem(key);
//     if (!guid) {
//         guid = generateGuid();
//         window.localStorage.setItem(key, guid);
//     }
//     //dispatch({ type: 'SET_GUID', payload: guid });
// }
