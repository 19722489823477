const initialState = { status: "EMPTY", data: {} };
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_QUESTIONNAIRES_START":
      if (state.status === "EMPTY" || state.status === "DONE") {
        return {
          ...initialState,
          status: "FETCHING",
        };
      } else {
        return {
          ...state,
          error: "STATE TRANSITION NOT DEFINED",
        };
      }

    case "GET_QUESTIONNAIRES_RECEIVE_DOC":
      if (action.id && action.payload) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.id]: { data: action.payload, id: action.id },
          },
        };
      } else {
        return {
          ...state,
          error: "STATE TRANSITION NOT DEFINED",
        };
      }
    case "GET_QUESTIONNAIRES_SUCCESS":
      if (state.status === "FETCHING") {
        return {
          ...state,
          error: null,
          status: "DONE",
        };
      } else {
        return {
          ...state,
          error: "STATE TRANSITION NOT DEFINED",
        };
      }
    default:
      return state;
  }
};

export default reducer;
