// Just sets payload to store
const initialState = {
  unsubscribe: null,
  user: null,
  loading: false,
  loaded: false,
  signedOut: false
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SIGNIN_IN_STARTED':
      return { ...state, loading: true, loaded: false };
    case 'SIGNIN_STORE_UNSUBSCRIBE':
      // console.log(action.payload)
      if (action.payload) {
        // unSubscribe from old changes if saving a new subscription
        if (state.unsubscribe && typeof state.unsubscribe === 'function') {
          state.unsubscribe();
        }
        return { ...state, unsubscribe: action.payload };
      }
      console.log('Illegal state');
      return state;
    case 'SIGN_OUT_UNSUBSCRIBE':
      // unSubscribe from old status changes
      if (state.unsubscribe && typeof state.unsubscribe === 'function') {
        state.unsubscribe();
        return { ...state, unsubscribe: null };
      }
      return state;
    case 'SIGNIN_SUCCESS__UID':
      // unSubscribe from old status changes
      if (action.user && !state.user) {
        return {
          ...state,
          ...{ user: action.user },
          loaded: true,
          loading: false
        };
      }
      console.log('already signed in or no user');
      return state;
    case 'SIGNOUT_SUCCESS':
      // unSubscribe from old status changes
      if (state.user) {
        return {
          ...state,
          user: null,
          loading: false,
          loaded: false,
          signedOut: true
        };
      }
      console.log('already signed out');

      return state;
    default:
      return state;
  }
};
export default authReducer;
