export const payloadMergingReducer = (key, initialState) => (
  state = initialState,
  action
) => {
  switch (action.type) {
    case key:
      if (action.payload) {
        return { ...state, ...action.payload };
      } else {
        return state;
      }
    default:
      return state;
  }
};
