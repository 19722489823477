const initialState = { status: 'EMPTY', data: {}, roles: {} };
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USERS_START':
      if (state.status === 'EMPTY') {
        return {
          data: initialState.data,
          roles: {},
          status: 'FETCHING'
        };
      } else {
        if (state.status === 'DONE') {
          return {
            data: state.data,
            roles: {},
            status: 'REFRESHING'
          };
        }
      }
      return {
        data: {},
        roles: {},
        status: 'error',
        error: 'Illegal state transition'
      };

    case 'GET_USERS_RECEIVE_DOC':
      if (action.id && action.payload) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.id]: { data: action.payload, id: action.id }
          }
        };
      } else {
        return {
          ...state,
          status: 'error',
          error: 'STATE TRANSITION NOT DEFINED'
        };
      }

    case 'GET_USER_ROLE_RECEIVE_DOC':
      if (action.id && action.payload) {
        return {
          ...state,
          roles: {
            ...state.roles,
            [action.id]: { data: action.payload, id: action.id }
          }
        };
      } else {
        return {
          ...state,
          status: 'error',
          error: 'STATE TRANSITION NOT DEFINED'
        };
      }

    case 'GET_USERS_SUCCESS':
      if (state.status === 'FETCHING' || state.status === 'REFRESHING') {
        return {
          ...state,
          error: null,
          status: 'DONE'
        };
      } else {
        return {
          ...state,
          error: 'STATE TRANSITION NOT DEFINED'
        };
      }
    default:
      return state;
  }
};

export default reducer;
