import React, { Suspense, lazy } from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  // Switch,
  Route,
  // Link
} from "react-router-dom";

const QuestionnaireFlow = lazy(() => import("./pages/QuestionnaireFlow"));
const SignInScreen = lazy(() => import("./pages/uiConfig"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const ProfileAdminPage = lazy(() => import("./pages/ProfileAdminPage"));
const QuestionnaireDetails = lazy(() => import("./pages/QuestionnaireDetails"));
const Preview = lazy(() => import("./pages/QuestionnairePreview"));
const Results = lazy(() => import("./pages/QuestionnaireResults"));
const QuestionnaireAdmin = lazy(() => import("./pages/QuestionnaireAdmin"));
function App() {
  return (
    <Suspense fallback={<div style={{ padding: "3rem" }}>...</div>}>
      <Router>
        <Route
          path={["/answer/:questionnaireId/:lang", "/answer/:questionnaireId"]}
          component={QuestionnaireFlow}
        />
        <Route path={["/kirjautuminen"]} exact component={SignInScreen} />
        <Route
          path={["/hallintapaneeli/profiili"]}
          exact
          component={ProfilePage}
        />
        <Route
          path={["/hallintapaneeli/kayttaja/:userId"]}
          exact
          component={ProfileAdminPage}
        />
        <Route
          path={["/hallintapaneeli"]}
          exact
          component={QuestionnaireAdmin}
        />
        <Route
          path={["/hallintapaneeli/kysely/:questionnaireId"]}
          exact
          component={QuestionnaireDetails}
        />
        <Route
          path="/hallintapaneeli/kysely/:questionnaireId/results"
          exact
          component={Results}
        />
        <Route
          path="/hallintapaneeli/kysely/:questionnaireId/questions"
          exact
          component={Preview}
        />
        <Route path={["", "/"]} exact>
          <div className="App" style={{ padding: "2rem" }}>
            <header className="App-header">
              <h1>Nothing to see here, move along</h1>
              <code></code>
            </header>
          </div>
        </Route>
      </Router>
    </Suspense>
  );
}

export default App;
