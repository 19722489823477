const reducer = (state = 'NOT_INITIALIZED', action) => {
  switch (action.type) {
    case 'SIGNIN_SUCCESS':
      return 'SIGNED_IN';
    case 'SIGNIN_SUCCESS__UID':
      return 'SIGNED_IN';
    default:
      return state;
  }
};

export default reducer;
