import { db } from "./initFirebase";

export const sendAnswer = (answerKey, answer, guid, questionnaireId) => {
  console.log({ answerKey, answer, guid, questionnaireId });
  return (dispatch) => {
    if (!guid || !answer || !questionnaireId) {
      throw new Error("Missing details for sending");
    }
    const payload = {
      sessionId: guid,
      questionnaireId: questionnaireId,
      answers: {
        [answerKey]: answer,
      },
    };

    db.collection("answers")
      .doc(questionnaireId)
      .collection("answers")
      .doc(guid)
      .set(payload, { merge: true })
      .catch((error) => {
        console.log(error, payload);
      });
  };
};
