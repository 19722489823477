import { combineReducers } from "redux";
import appState from "./appState";
import questionnaire from "./questionnaire";
import questionnaires from "./questionnaires";
import questionnaireAdmin from "./questionnaireAdmin";
import answers from "./answers";
import results from "./results";
import guid from "./guid";
import auth from "./auth";
import users from "./users";
import progression from "./progression";
import payloadReducer from "./payloadReducer";
export default combineReducers({
  appState,
  questionnaire,
  questionnaires,
  questionnaireAdmin,
  answers,
  results,
  guid,
  auth,
  progression,
  users,
  themeOrdering: payloadReducer("THEME_ORDERING"),
});
